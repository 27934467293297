@import "https://fonts.googleapis.com/css2?family=Exo:wght@200;400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Metal+Mania:wght@400;600&display=swap";
@import "https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.-bottom-1 {
  bottom: -.25rem;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-4 {
  bottom: -1rem;
}

.-left-1 {
  left: -.25rem;
}

.-left-4 {
  left: -1rem;
}

.-right-1 {
  right: -.25rem;
}

.-right-2 {
  right: -.5rem;
}

.-right-3 {
  right: -.75rem;
}

.-right-5 {
  right: -1.25rem;
}

.-right-6 {
  right: -1.5rem;
}

.-right-9 {
  right: -2.25rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-2 {
  top: -.5rem;
}

.-top-3 {
  top: -.75rem;
}

.-top-6 {
  top: -1.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-\[100px\] {
  bottom: 100px;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: .25rem;
}

.left-1\/2 {
  left: 50%;
}

.left-16 {
  left: 4rem;
}

.left-2 {
  left: .5rem;
}

.left-3 {
  left: .75rem;
}

.left-4 {
  left: 1rem;
}

.left-5 {
  left: 1.25rem;
}

.left-\[-84px\] {
  left: -84px;
}

.left-\[100\%\] {
  left: 100%;
}

.left-\[1px\] {
  left: 1px;
}

.left-\[20\%\] {
  left: 20%;
}

.left-\[50\%\] {
  left: 50%;
}

.left-\[80\%\] {
  left: 80%;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: .25rem;
}

.right-16 {
  right: 4rem;
}

.right-2 {
  right: .5rem;
}

.right-24 {
  right: 6rem;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-5 {
  right: 1.25rem;
}

.right-6 {
  right: 1.5rem;
}

.right-8 {
  right: 2rem;
}

.right-\[-55px\] {
  right: -55px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-10 {
  top: 2.5rem;
}

.top-12 {
  top: 3rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.top-7 {
  top: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.top-\[-13px\] {
  top: -13px;
}

.top-\[10\%\] {
  top: 10%;
}

.top-\[20\%\] {
  top: 20%;
}

.top-\[2px\] {
  top: 2px;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.order-1 {
  order: 1;
}

.order-last {
  order: 9999;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-\[10px\] {
  margin-top: -10px;
}

.-mt-\[8px\] {
  margin-top: -8px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-3 {
  height: .75rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-\[11\.9rem\] {
  height: 11.9rem;
}

.h-\[114px\] {
  height: 114px;
}

.h-\[14\.65rem\] {
  height: 14.65rem;
}

.h-\[196px\] {
  height: 196px;
}

.h-\[220px\] {
  height: 220px;
}

.h-\[268px\] {
  height: 268px;
}

.h-\[270px\] {
  height: 270px;
}

.h-\[300px\] {
  height: 300px;
}

.h-\[900px\] {
  height: 900px;
}

.h-auto {
  height: auto;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-\[300px\] {
  max-height: 300px;
}

.max-h-\[360px\] {
  max-height: 360px;
}

.max-h-\[500px\] {
  max-height: 500px;
}

.max-h-full {
  max-height: 100%;
}

.min-h-\[100px\] {
  min-height: 100px;
}

.min-h-\[250px\] {
  min-height: 250px;
}

.min-h-\[400px\] {
  min-height: 400px;
}

.min-h-\[46px\] {
  min-height: 46px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-\[1052px\] {
  width: 1052px;
}

.w-\[108px\] {
  width: 108px;
}

.w-\[138px\] {
  width: 138px;
}

.w-\[140px\] {
  width: 140px;
}

.w-\[196px\] {
  width: 196px;
}

.w-\[240px\] {
  width: 240px;
}

.w-\[320px\] {
  width: 320px;
}

.w-\[33\.33\%\] {
  width: 33.33%;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[560px\] {
  width: 560px;
}

.w-\[680px\] {
  width: 680px;
}

.w-\[70\%\] {
  width: 70%;
}

.w-\[80px\] {
  width: 80px;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[90px\] {
  min-width: 90px;
}

.max-w-28 {
  max-width: 7rem;
}

.max-w-\[260px\] {
  max-width: 260px;
}

.max-w-\[30px\] {
  max-width: 30px;
}

.max-w-\[700px\] {
  max-width: 700px;
}

.max-w-\[800px\] {
  max-width: 800px;
}

.max-w-\[840px\] {
  max-width: 840px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-1\/2 {
  flex-basis: 50%;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-1\/4 {
  flex-basis: 25%;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-x-\[-1\] {
  --tw-scale-x: -1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-none {
  cursor: none;
}

.cursor-pointer {
  cursor: pointer;
}

.list-disc {
  list-style-type: disc;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-items-end {
  place-items: end;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-1 {
  gap: .25rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-b-xl {
  border-bottom-right-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.\!border-0 {
  border-width: 0 !important;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[5px\] {
  border-width: 5px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t-\[4px\] {
  border-top-width: 4px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.\!border-red-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.border-\[\#150b49\] {
  --tw-border-opacity: 1;
  border-color: rgb(21 11 73 / var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-amber-600\/30 {
  border-color: #d977064d;
}

.border-amber-600\/80 {
  border-color: #d97706cc;
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-black\/30 {
  border-color: #0000004d;
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-gray-200\/20 {
  border-color: #e5e7eb33;
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-600\/30 {
  border-color: #4b55634d;
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-gray-700\/30 {
  border-color: #3741514d;
}

.border-gray-700\/40 {
  border-color: #37415166;
}

.border-gray-700\/60 {
  border-color: #37415199;
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-indigo-300\/60 {
  border-color: #a5b4fc99;
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-mainLight {
  --tw-border-opacity: 1;
  border-color: rgb(29 18 94 / var(--tw-border-opacity));
}

.border-mainLight\/80 {
  border-color: #1d125ecc;
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-violet-100\/10 {
  border-color: #ede9fe1a;
}

.border-violet-300\/10 {
  border-color: #c4b5fd1a;
}

.border-violet-900\/50 {
  border-color: #4c1d9580;
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: #ffffff1a;
}

.border-white\/20 {
  border-color: #fff3;
}

.border-opacity-30 {
  --tw-border-opacity: .3;
}

.bg-\[\#0e0737\] {
  --tw-bg-opacity: 1;
  background-color: rgb(14 7 55 / var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-500\/10 {
  background-color: #f59e0b1a;
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-600\/20 {
  background-color: #d9770633;
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-700\/95 {
  background-color: #b45309f2;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-800\/30 {
  background-color: #1e40af4d;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-700\/60 {
  background-color: #37415199;
}

.bg-gray-700\/80 {
  background-color: #374151cc;
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900\/30 {
  background-color: #0707074d;
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-main {
  --tw-bg-opacity: 1;
  background-color: rgb(12 6 53 / var(--tw-bg-opacity));
}

.bg-main\/30 {
  background-color: #0c06354d;
}

.bg-main\/50 {
  background-color: #0c063580;
}

.bg-main\/80 {
  background-color: #0c0635cc;
}

.bg-main\/95 {
  background-color: #0c0635f2;
}

.bg-mainLight {
  --tw-bg-opacity: 1;
  background-color: rgb(29 18 94 / var(--tw-bg-opacity));
}

.bg-mainLight\/30 {
  background-color: #1d125e4d;
}

.bg-mainLight\/50 {
  background-color: #1d125e80;
}

.bg-mainLight\/60 {
  background-color: #1d125e99;
}

.bg-mainLight\/70 {
  background-color: #1d125eb3;
}

.bg-mainLight\/80 {
  background-color: #1d125ecc;
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.bg-red-600\/30 {
  background-color: #dc26264d;
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-sky-100\/95 {
  background-color: #e0f2fef2;
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-500\/50 {
  background-color: #0ea5e980;
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: #ffffff1a;
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-indigo-300\/90 {
  --tw-gradient-from: #a5b4fce6 var(--tw-gradient-from-position);
  --tw-gradient-to: #a5b4fc00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-500\/90 {
  --tw-gradient-to: #3b82f6e6 var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.fill-blue-600 {
  fill: #2563eb;
}

.fill-current {
  fill: currentColor;
}

.fill-gray-600 {
  fill: #4b5563;
}

.fill-white\/30 {
  fill: #ffffff4d;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-\[6px\] {
  padding: 6px;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.font-\[Exo\] {
  font-family: Exo;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.\!text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.\!text-red-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(252 165 165 / var(--tw-text-opacity)) !important;
}

.text-\[\#8f8f8f\] {
  --tw-text-opacity: 1;
  color: rgb(143 143 143 / var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-red-100 {
  --tw-text-opacity: 1;
  color: rgb(254 226 226 / var(--tw-text-opacity));
}

.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgb(248 113 113 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-violet-300\/20 {
  color: #c4b5fd33;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-white\/40 {
  color: #fff6;
}

.text-white\/50 {
  color: #ffffff80;
}

.underline {
  text-decoration-line: underline;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-20 {
  opacity: .2;
}

.opacity-30 {
  opacity: .3;
}

.opacity-40 {
  opacity: .4;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-70 {
  opacity: .7;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_0_20px_\#f97315a8_inset\] {
  --tw-shadow: 0 0 20px #f97315a8 inset;
  --tw-shadow-colored: inset 0 0 20px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-blue-500\/50 {
  --tw-shadow-color: #3b82f680;
  --tw-shadow: var(--tw-shadow-colored);
}

.shadow-red-500\/50 {
  --tw-shadow-color: #ef444480;
  --tw-shadow: var(--tw-shadow-colored);
}

.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  visibility: visible;
  letter-spacing: .02em;
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
  background-color: #090029;
  background-size: cover;
  margin: 0;
  font-family: Exo, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.35;
}

.zombie-font {
  font-family: Metal Mania, sans-serif;
}

.exo-font {
  font-family: Exo, sans-serif;
}

.landing-bg {
  background: url("body-bg.6107bbf5.webp") top / cover repeat-y;
}

.top-block {
  background: url("hero-bg1.a500aa0f.webp") top / contain no-repeat;
}

.title-shadow {
  text-shadow: 3px 3px #893ebb;
}

.active {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.landing-bg .active {
  background-color: #0000;
}

.page-item, .page-item.active {
  background-color: #0000;
  margin-right: .5rem;
  font-weight: 600;
  display: inline-block;
}

.page-item .page-link {
  border-radius: 9999px;
  width: 2.25rem;
  height: 2.25rem;
  padding-top: .5rem;
  display: inline-block;
}

.page-item.active .page-link {
  --tw-bg-opacity: 1;
  background-color: rgb(6 94 136 / var(--tw-bg-opacity));
}

.timeline-body {
  z-index: 2;
  width: 100%;
  position: relative;
}

.mint-success {
  cursor: pointer;
  background: #34336d;
}

.mint-success:hover {
  background-color: #373577;
}

#landing a, a.link {
  color: orange;
}

#landing a:hover, a.link:hover {
  border-bottom: 2px dotted orange;
}

.move-discovery-icon {
  animation: 3s linear infinite alternate move_discovery_icon;
}

@keyframes move_discovery_icon {
  0% {
    top: 20%;
    left: 20%;
  }

  25% {
    top: 20%;
    left: 60%;
  }

  50% {
    top: 56%;
    left: 20%;
  }

  75% {
    top: 40%;
    left: 50%;
  }

  100% {
    left: 20%;
  }
}

.rotate-card {
  transform-style: preserve-3d;
  animation: 2s ease-in alternate rotate_zoom_card;
}

@keyframes rotate_zoom_card {
  80% {
    opacity: .3;
    transform: rotateZ(360deg)scale(.5);
  }

  100% {
    opacity: .1;
    transform: rotateZ(500deg)scale(.2);
  }
}

.rotate-card-reverse {
  transform-style: preserve-3d;
  animation: .6s ease-in alternate rotate_zoom_card_reverse;
}

@keyframes rotate_zoom_card_reverse {
  0% {
    opacity: 0;
    transform: rotateZ(0)scale(0);
  }

  100% {
    opacity: 1;
    transform: rotateZ(-360deg)scale(1);
  }
}

.discovery-text-results {
  transform-style: preserve-3d;
  animation: .4s ease-in alternate move_left_text;
}

@keyframes move_left_text {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
    width: 360px;
  }
}

.heading-tertiary {
  color: inherit;
  font-size: 1.2rem;
  font-weight: bolder;
}

.staking-monster-light {
  box-shadow: 0 0 20px 5px #00ffff4d;
}

.staking-monster-bg {
  background: url("staking-monster.5762f990.png") center / cover no-repeat;
}

.text-shadow {
  text-shadow: 0 0 2px #140f3899;
}

.battleTabCounter {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .25rem;
  margin-left: .25rem;
  padding: .125rem .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.text-amber-600 .battleTabCounter {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.wallet-options-wrapper {
  margin-bottom: 0 !important;
}

.options-list-section:first-child {
  margin-bottom: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  gap: 0 !important;
}

.nws-modal-wrapper .nws-modal .modal-left {
  padding: 24px !important;
}

.nws-modal-overlay {
  background: #090029b3 !important;
}

.one-battle {
  background: linear-gradient(90deg, #150b49 0%, #0a012c 100%);
}

.win-lose {
  z-index: 2;
  border-top: 0 solid #0000;
  border-bottom: 64px solid #ef4444;
  border-left: 40px solid #0000;
  border-right: 0 solid #0000;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: -40px;
}

.bg-green-500 .win-lose {
  border-color: #0000 #0000 #22c55e;
}

.options-list-section-header {
  display: none;
}

.options-list-section {
  margin-bottom: 0 !important;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:left-\[2px\]:after {
  content: var(--tw-content);
  left: 2px;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:h-5:after {
  content: var(--tw-content);
  height: 1.25rem;
}

.after\:w-5:after {
  content: var(--tw-content);
  width: 1.25rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-300:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.after\:bg-white:after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-2:hover {
  border-width: 2px;
}

.hover\:\!border-red-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.hover\:border-amber-600:hover {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.hover\:border-amber-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.hover\:bg-amber-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.hover\:bg-amber-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.hover\:bg-black\/30:hover {
  background-color: #0000004d;
}

.hover\:bg-gray-600\/80:hover {
  background-color: #4b5563cc;
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:bg-main:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(12 6 53 / var(--tw-bg-opacity));
}

.hover\:bg-main\/50:hover {
  background-color: #0c063580;
}

.hover\:bg-mainLight:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 18 94 / var(--tw-bg-opacity));
}

.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.hover\:fill-white\/70:hover {
  fill: #ffffffb3;
}

.hover\:\!text-gray-500:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.hover\:\!text-red-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity)) !important;
}

.hover\:text-amber-400:hover {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.hover\:text-amber-600:hover {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(7 7 7 / var(--tw-text-opacity));
}

.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}

.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

.hover\:text-sky-200:hover {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.hover\:text-sky-400:hover {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.hover\:text-sky-600:hover {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-90:hover {
  opacity: .9;
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.focus\:bg-gray-900\/60:focus {
  background-color: #07070799;
}

.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.group:hover .group-hover\:block {
  display: block;
}

.peer:checked ~ .peer-checked\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.peer:focus ~ .peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .sm\:left-28 {
    left: 7rem;
  }

  .sm\:right-28 {
    right: 7rem;
  }

  .sm\:right-8 {
    right: 2rem;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mr-12 {
    margin-right: 3rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-1\/5 {
    width: 20%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-\[700px\] {
    width: 700px;
  }

  .sm\:w-\[740px\] {
    width: 740px;
  }

  .sm\:w-\[800px\] {
    width: 800px;
  }

  .sm\:w-\[816px\] {
    width: 816px;
  }

  .sm\:w-\[846px\] {
    width: 846px;
  }

  .sm\:w-\[900px\] {
    width: 900px;
  }

  .sm\:w-\[920px\] {
    width: 920px;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:max-w-\[40px\] {
    max-width: 40px;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:gap-0 {
    gap: 0;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-8 {
    gap: 2rem;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pt-28 {
    padding-top: 7rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:left-32 {
    left: 8rem;
  }

  .md\:right-32 {
    right: 8rem;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:ml-0\.5 {
    margin-left: .125rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mr-28 {
    margin-right: 7rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:block {
    display: block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:h-\[462px\] {
    height: 462px;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-\[780px\] {
    width: 780px;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:max-w-max {
    max-width: max-content;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:basis-3\/12 {
    flex-basis: 25%;
  }

  .md\:basis-9\/12 {
    flex-basis: 75%;
  }

  .md\:rotate-0 {
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:gap-7 {
    gap: 1.75rem;
  }

  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }
}

@media (min-width: 1024px) {
  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:-mt-28 {
    margin-top: -7rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-3 {
    height: .75rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-\[1060px\] {
    width: 1060px;
  }

  .lg\:w-\[1080px\] {
    width: 1080px;
  }

  .lg\:w-\[600px\] {
    width: 600px;
  }

  .lg\:w-\[840px\] {
    width: 840px;
  }

  .lg\:w-\[900px\] {
    width: 900px;
  }

  .lg\:w-\[920px\] {
    width: 920px;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:basis-1\/2 {
    flex-basis: 50%;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }

  .lg\:gap-14 {
    gap: 3.5rem;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1280px) {
  .xl\:ml-1 {
    margin-left: .25rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:ml-\[1\%\] {
    margin-left: 1%;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:basis-3\/4 {
    flex-basis: 75%;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:gap-20 {
    gap: 5rem;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .\32 xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .\32 xl\:ml-\[3\%\] {
    margin-left: 3%;
  }

  .\32 xl\:mr-10 {
    margin-right: 2.5rem;
  }

  .\32 xl\:mt-16 {
    margin-top: 4rem;
  }

  .\32 xl\:block {
    display: block;
  }

  .\32 xl\:w-3\/4 {
    width: 75%;
  }

  .\32 xl\:max-w-2xl {
    max-width: 42rem;
  }

  .\32 xl\:basis-1\/4 {
    flex-basis: 25%;
  }

  .\32 xl\:pl-14 {
    padding-left: 3.5rem;
  }
}

@media (prefers-color-scheme: dark) {
  .dark\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }

  .dark\:bg-main {
    --tw-bg-opacity: 1;
    background-color: rgb(12 6 53 / var(--tw-bg-opacity));
  }

  .dark\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgb(75 85 99 / var(--tw-text-opacity));
  }

  .peer:focus ~ .dark\:peer-focus\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity));
  }
}

#near-wallet-selector-modal {
  --backdrop-bg: #26262630;
  --heading-color: #222;
  --text-color: #676767;
  --sidebar-border-color: #ededed;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: transparent;
  --wallet-option-border-color: #a7a7a730;
  --wallet-option-bg-hover: #6494ee3d;
  --wallet-option-outline-color: #6494ee;
  --content-bg: #fafafa;
  --change-path-bg: #ededed;
  --home-button-bg: #ededed;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #ededed;
  --close-button-fill-icon-color: #9f9f9f;
  --spinner-color: #676767;
  --bottom-section: #ececec;
  --mobile-text: #3e3e3e;
  --connected-green: #47e586;
  --gradient-dark-icon: radial-gradient(297.59% 179.46% at 91.67% -25%, #aec2ea 0%, #95d1e4 100%);
  --get-wallet-option-bg-color: #f8f9fa;
  --get-wallet-option-bg-color-hover: #6494ee3d;
  --get-wallet-option-border-color: #c1c1c1;
  --get-wallet-outline-color: #6494ee;
  --get-wallet-option-box-shadow: 0px 2px 2px #1116180a;
  --secondary-button-bg-color: #4f7cd129;
  --secondary-button-border-color: #4f7cd129;
  --secondary-button-text-color: #4f7cd1;
  --what-wallet-icon-color: #fff;
  --deprecated-icon-bg-color: #d7e2f7;
  --deprecated-icon-bg-color-selected: #4f7cd1;
}

#near-wallet-selector-modal .dark-theme {
  --backdrop-bg: #26262630;
  --heading-color: #fff;
  --text-color: #c1c1c1;
  --sidebar-border-color: #313030;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: #262626cc;
  --wallet-option-border-color: #a7a7a730;
  --wallet-option-bg-hover: #4f7cd13d;
  --wallet-option-outline-color: #4f7cd1;
  --content-bg: #232323;
  --change-path-bg: #161616;
  --home-button-bg: #313030;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #313030;
  --close-button-fill-icon-color: #c1c1c1;
  --spinner-color: #fff;
  --bottom-section: #131313;
  --mobile-text: #c1c1c1;
  --connected-green: #47e586;
  --gradient-dark-icon: radial-gradient(124.37% 124.37% at 91.67% -25%, #112140 0%, #262b35 100%);
  --get-wallet-option-bg-color: #0000001f;
  --get-wallet-option-bg-color-hover: #4f7cd13d;
  --get-wallet-option-border-color: #313030;
  --get-wallet-outline-color: #4f7cd1;
  --get-wallet-option-box-shadow: 0px 2px 2px #1116180a;
  --secondary-button-bg-color: #4f7cd129;
  --secondary-button-border-color: #4f7cd1;
  --secondary-button-text-color: #fff;
  --what-wallet-icon-color: #568ffc;
  --deprecated-icon-bg-color: #2f394e;
  --deprecated-icon-bg-color-selected: #4f7cd1;
}

@media (prefers-color-scheme: dark) {
  #near-wallet-selector-modal {
    --backdrop-bg: #26262630;
    --heading-color: #fff;
    --text-color: #c1c1c1;
    --sidebar-border-color: #313030;
    --selected-wallet-bg: #4f7cd1;
    --selected-wallet-bg-hover: #262626cc;
    --wallet-option-border-color: #a7a7a730;
    --wallet-option-bg-hover: #4f7cd13d;
    --wallet-option-outline-color: #4f7cd1;
    --content-bg: #232323;
    --change-path-bg: #161616;
    --home-button-bg: #313030;
    --confirm-button-bg: #5f8afa;
    --confirm-button-bg-hover: #5aa6ff;
    --error: #db5555;
    --close-button-bg-color: #313030;
    --close-button-fill-icon-color: #c1c1c1;
    --spinner-color: #fff;
    --bottom-section: #131313;
    --mobile-text: #c1c1c1;
    --connected-green: #47e586;
    --gradient-dark-icon: radial-gradient(124.37% 124.37% at 91.67% -25%, #112140 0%, #262b35 100%);
    --get-wallet-option-bg-color: #0000001f;
    --get-wallet-option-bg-color-hover: #4f7cd13d;
    --get-wallet-option-border-color: #313030;
    --get-wallet-outline-color: #4f7cd1;
    --get-wallet-option-box-shadow: 0px 2px 2px #1116180a;
    --secondary-button-bg-color: #4f7cd129;
    --secondary-button-border-color: #4f7cd1;
    --secondary-button-text-color: #fff;
    --what-wallet-icon-color: #568ffc;
    --deprecated-icon-bg-color: #2f394e;
    --deprecated-icon-bg-color-selected: #4f7cd1;
  }
}

.nws-modal-wrapper {
  opacity: 0;
  visibility: hidden;
  color: var(--wallet-selector-text-color, var(--text-color));
  z-index: 80;
  justify-content: center;
  align-items: center;
  font-family: Manrope, sans-serif;
  font-weight: 500;
  display: flex;
  position: fixed;
  inset: 0;
}

.nws-modal-wrapper .nws-modal-overlay {
  background: var(--wallet-selector-backdrop-bg, var(--backdrop-bg));
  width: 100%;
  height: 100%;
  position: absolute;
}

.nws-modal-wrapper .nws-modal {
  background: var(--wallet-selector-content-bg, var(--content-bg));
  background-color: var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 16px;
  width: 100%;
  max-width: 812px;
  height: 555px;
  font-size: 16px;
  line-height: 1.6;
  transition: visibility linear, opacity .25s, transform .25s;
  display: inline-flex;
  position: absolute;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.nws-modal-wrapper .nws-modal * {
  box-sizing: border-box;
}

.nws-modal-wrapper .nws-modal button {
  cursor: pointer;
  border: none;
  padding: .5em 1em;
}

.nws-modal-wrapper .nws-modal .modal-left {
  border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
  width: 35%;
  height: 100%;
  padding: 32px 24px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .modal-left::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper {
  margin-bottom: 20px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
  margin-top: 0;
  margin-bottom: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
}

.single-wallet {
  cursor: pointer;
  flex-flow: row;
  place-content: center space-between;
  align-items: center;
  column-gap: 24px;
  width: 100%;
  padding: 12px;
  display: flex;
}

.single-wallet:focus-within {
  outline: var(--wallet-option-outline-color) solid 2px;
}

.single-wallet .icon {
  width: auto;
  height: 48px;
}

.single-wallet .icon img {
  width: 100%;
  max-width: 48px;
  height: auto;
}

.single-wallet .content {
  width: 50%;
}

.single-wallet .content .name {
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.single-wallet .content .description {
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.single-wallet .button-get {
  margin-left: auto;
  margin-right: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar {
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  column-gap: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover {
  background-color: var(--wallet-selector-wallet-option-bg-hover, var(--wallet-option-bg-hover));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  outline: none;
  padding: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet .content .title {
  color: #fff;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet .icon, .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet .title {
  opacity: .4;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet {
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .warning-triangle {
  background: var(--content-bg);
  border-top-left-radius: 63px;
  border-bottom-left-radius: 10px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 42px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover .warning-triangle {
  background-color: var(--deprecated-icon-bg-color);
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.selected-wallet.sidebar .warning-triangle {
  background-color: var(--deprecated-icon-bg-color-selected);
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .icon {
  background-color: #fff;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 5px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.connected-wallet .icon:before {
  content: "";
  background-color: var(--connected-green);
  border: 3px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet.connected-wallet .icon:before {
  border: 3px solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content {
  width: auto;
  height: 32px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .description {
  display: none;
}

.nws-modal-wrapper .nws-modal .modal-right {
  width: 65%;
  padding: 32px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
  margin-top: 91px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper-err {
  margin-top: 45px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  gap: 10px;
  margin: 20px 0 0;
  padding: 0;
  display: grid;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what {
  flex-flow: row;
  place-content: center flex-start;
  align-items: flex-start;
  gap: 32px;
  width: 100%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what + .wallet-what {
  margin-top: 50px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side {
  background: var(--gradient-dark-icon);
  color: var(--what-wallet-icon-color);
  border-radius: 12px;
  width: 56px;
  height: 56px;
  padding: 8px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side svg {
  opacity: .7;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side {
  width: 100%;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side h3 {
  color: var(--wallet-selector-text-color, var(--heading-color));
  margin: 0 auto 8px 0;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side p {
  word-break: break-word;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin: 4px auto;
  font-size: 18px;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-body.get-wallet-body {
  row-gap: 16px;
  margin-top: 64px;
  padding-left: 32px;
  padding-right: 0;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  font: inherit;
  color: #fff;
  border-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border: .1em solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border-radius: 4px;
  margin: 25px auto 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
  background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
  color: #6494ee;
  border-width: 0;
  border-radius: 4px;
  margin: 4px 0;
  padding: 8px 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-header {
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
  cursor: pointer;
  background-color: var(--wallet-selector-close-button-bg-color, var(--close-button-bg-color));
  border: 0;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  fill: var(--wallet-selector-close-button-fill-icon-color, var(--close-button-fill-icon-color));
}

.nws-modal-wrapper .nws-modal .back-button {
  background: none;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 4px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-header-wrapper {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button:active {
  background: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  pointer-events: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .action-buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .lang-selector-wrapper {
  display: none;
  position: absolute;
  bottom: 15px;
  right: 50px;
}

.nws-modal-wrapper .nws-modal .lang-selector {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  text-indent: 54px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjc1IDE5LjI1TDE2IDEyLjc1TDE5LjI1IDE5LjI1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0IDE3LjI1SDE4IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNzUgNi43NUgxMy4yNSIgc3Ryb2tlPSIjQzFDMUMxIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05IDYuNVY0Ljc1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTExLjI1IDYuNzVDMTEuMjUgNi43NSAxMS4yNSA5LjI1IDkuMjUgMTEuMjVDNy4yNSAxMy4yNSA0Ljc1IDEzLjI1IDQuNzUgMTMuMjUiIHN0cm9rZT0iI0MxQzFDMSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuMjUgMTMuMjVDMTIuMjUgMTMuMjUgOS43NSAxMy4yNSA3Ljc1IDExLjI1QzcuMzQ1NTEgMTAuODQ1NSA2Ljc1IDkuNzUgNi43NSA5Ljc1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") 5px / 20px 20px no-repeat;
  border: 0;
  outline: none;
  width: 54px;
  height: 32px;
  padding-right: 54px;
  font-size: 16px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .lang-selector-wrapper:after {
  content: "";
  border: 2px solid #72727a;
  border-width: 2px 2px 0 0;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  transform: rotate(135deg)translateY(-50%);
}

.nws-modal-wrapper .nws-modal .switch-network-message-wrapper .content .network-id {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper {
  padding: 0 26px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
  justify-content: center;
  margin-top: 53px;
  margin-bottom: 40px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description p {
  margin: 14px 0;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description .specify-path {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  cursor: pointer;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
  justify-content: center;
  margin-top: 91px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .display-path {
  background: var(--home-button-bg);
  color: #606060;
  letter-spacing: 1px;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path {
  background: var(--change-path-bg);
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  width: 63px;
  margin-left: 10px;
  padding: 0 5px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .path-value {
  text-align: center;
  width: 100%;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper p {
  margin: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .path-description {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link {
  text-align: center;
  color: var(--selected-wallet-bg);
  cursor: pointer;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link a {
  color: var(--selected-wallet-bg);
  font-size: 14px;
  text-decoration: none;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper {
  flex-direction: column;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper button {
  background-color: var(--home-button-bg);
  border: none;
  width: 23px;
  padding: 0;
}

.nws-modal-wrapper .nws-modal .no-accounts-found-wrapper {
  margin-top: 50px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account {
  margin-top: 20px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper {
  justify-content: center;
  margin-top: 90px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper input {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  border: 1px solid var(--confirm-button-bg-hover);
  border-radius: 50px;
  padding: 6px 8px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper p {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper {
  justify-content: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper button {
  color: var(--selected-wallet-bg);
  border: 1px solid var(--selected-wallet-bg);
  background-color: #0000;
  border-radius: 20px;
  width: 78px;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 600;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .form {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
  color: var(--text-color);
  flex-direction: column;
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account input[type="checkbox"] {
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  width: 25px;
  padding: 10px;
  transform: scale(1.7);
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control label {
  color: inherit;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .action-buttons {
  justify-content: flex-end;
}

.nws-modal-wrapper .nws-modal .overview-wrapper p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .accounts {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .refresh-link {
  color: #5f8afa;
  cursor: pointer;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data p {
  margin: 0 0 0 10px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper p {
  margin: 16px 0;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box {
  width: 40px;
  height: 40px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box img {
  width: 100%;
  height: auto;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: visibility linear, opacity .25s;
}

.nws-modal-wrapper .spinner {
  --size: 160px;
  --border: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.nws-modal-wrapper .spinner .icon {
  width: calc(var(--size) / 1.2);
  height: calc(var(--size) / 1.2);
  border: 1px solid #0000000d;
  border-radius: 50%;
  box-shadow: 0 10px 20px #0000000d;
}

.nws-modal-wrapper .spinner img {
  width: 100%;
  height: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .nws-modal-wrapper .nws-modal-wrapper .nws-modal {
    width: 250px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list input {
    max-width: 140px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
    flex-direction: column;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control select {
    text-align: center;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .spinner .icon {
  box-shadow: 0 10px 20px #ffffff0d;
}

@media (prefers-color-scheme: dark) {
  .nws-modal-wrapper .spinner .icon {
    box-shadow: 0 10px 20px #ffffff0d;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.connecting-wrapper {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.connecting-wrapper .content .icon {
  background-color: #fff;
  border-radius: 10px;
  width: 80px;
  height: 80px;
  padding: 10px;
  position: relative;
}

.connecting-wrapper .content .icon .green-dot {
  background-color: var(--connected-green);
  border: 5px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -10px;
  right: -10px;
}

.connecting-wrapper .content .icon img {
  width: 100%;
  height: auto;
}

.connecting-wrapper .content h3 {
  margin: 16px 0;
}

.connecting-wrapper .content .connecting-name {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-message {
  margin-top: 16px;
  font-size: 14px;
}

.connecting-wrapper .content .connecting-details {
  align-items: center;
  margin-top: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-details span {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.connecting-wrapper .content .connecting-details .spinner {
  width: 25px;
  height: auto;
  animation: 2s linear infinite spinner;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection button {
  background: var(--secondary-button-bg-color);
  border: 1px solid var(--secondary-button-border-color);
  color: var(--secondary-button-text-color);
  border-radius: 4px;
  gap: 8px;
  padding: 10px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper {
  vertical-align: middle;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error {
  color: #ce5a6f;
  vertical-align: middle;
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper .error svg {
  color: var(--wallet-selector-content-bg, var(--content-bg));
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper p {
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 14px 0;
  display: -webkit-box;
  overflow: hidden;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .success {
  color: #4fd98f;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  padding: 25px;
}

.connecting-wrapper .content .connecting-details {
  margin-top: 20px;
}

.connecting-wrapper .wallet-connected-success {
  align-items: center;
  display: flex;
}

.connecting-wrapper .wallet-connected-success svg {
  color: var(--content-bg);
}

.connecting-wrapper .wallet-connected-success span {
  color: var(--wallet-selector-connected-green, var(--connected-green));
  margin-left: 10px;
  font-size: 14px;
}

.single-wallet-get {
  border: 1px solid var(--get-wallet-option-border-color);
  box-shadow: var(--get-wallet-option-box-shadow);
  background-color: var(--get-wallet-option-bg-color);
  cursor: pointer;
  border-radius: 8px;
  flex-flow: column wrap;
  flex-grow: 1;
  place-content: center space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto auto;
  padding: 14px;
  display: flex;
  position: relative;
}

.single-wallet-get:hover {
  background: var(--get-wallet-option-bg-color-hover);
}

.single-wallet-get:focus-within {
  outline: var(--get-wallet-outline-color) solid 2px;
}

.single-wallet-get .icon {
  background-color: #fff;
  border-radius: 5px;
  width: 32px;
  min-width: 32px;
  height: 32px;
  max-height: 32px;
  margin-bottom: 8px;
  padding: 5px;
  position: relative;
}

.single-wallet-get .icon img {
  width: 100%;
  max-width: 48px;
  height: auto;
}

.single-wallet-get .title {
  color: var(--heading-color);
  text-align: center;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.single-wallet-get .type {
  color: var(--text-color);
  text-align: center;
  margin-top: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.single-wallet-get .small-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.single-wallet-get .small-icon svg {
  color: #4c5155;
}

.single-wallet-get:hover .small-icon svg {
  color: #4f7cd1;
}

.single-wallet-get .description {
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.single-wallet-get .button-get {
  margin-left: auto;
  margin-right: 0;
}

.connected-flag {
  color: var(--connected-green);
  border: 1px solid var(--connected-green);
  border-radius: 50px;
  align-items: center;
  padding: 3px 10px;
  display: flex;
}

.connected-flag:before {
  content: "";
  background-color: var(--connected-green);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  display: block;
}

.scan-qr-code {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.scan-qr-code .qr-code > div:first-of-type {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px;
}

.scan-qr-code .qr-code {
  border: 1px solid var(--wallet-selector-content-bg, var(--content-bg));
  text-align: center;
  border-radius: 4px;
  height: calc(100% - 200px);
  margin-top: 64px;
}

.scan-qr-code .qr-code svg {
  width: 239px;
  height: 239px;
}

.scan-qr-code .qr-code .copy-btn {
  cursor: pointer;
  text-align: center;
  color: var(--selected-wallet-bg);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.scan-qr-code .qr-code .copy-btn svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.scan-qr-code .qr-code .notification {
  font-size: 14px;
}

.scan-qr-code .footer {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;
  font-size: 14px;
  display: flex;
  position: relative;
  bottom: 0;
}

.scan-qr-code .footer .btn {
  background: var(--secondary-button-bg-color);
  color: var(--secondary-button-text-color);
  border: 1px solid var(--secondary-button-border-color);
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 577px) {
  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile {
    display: none;
  }

  .scan-qr-code .footer {
    position: absolute;
  }
}

@media (min-width: 769px) {
  .button-spacing {
    margin: 90px;
  }
}

@media (max-width: 768px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    margin-top: 45px;
  }

  .button-spacing {
    margin: 45px;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
    width: 40%;
    height: 100%;
    max-height: 70vh;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 60%;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    padding: 0 0 0 10px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
    margin-top: 30px;
    margin-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
    margin-top: 20px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
    text-align: center;
  }

  .nws-modal-wrapper .wallet-not-installed-wrapper > p {
    max-width: 500px;
    margin: 20px 0 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
    background-color: var(--wallet-selector-home-button-bg, var(--content-bg));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-hide {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile p {
    text-align: center;
    margin: auto;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal {
    background: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
    border-radius: 16px 16px 0 0;
    width: 100%;
    height: 500px;
    display: block;
    bottom: 0;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    background-color: var(--wallet-selector-content-bg, var(--content-bg));
    width: 100%;
    height: auto;
    padding: 32px 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body {
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body .wallet-options-wrapper {
    margin: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    background-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
    width: 100%;
  }

  .nws-modal-wrapper .nws-modal .connecting-wrapper .content {
    padding-top: 0;
  }

  .connecting-wrapper .content .icon .green-dot {
    border-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
    text-align: center;
    margin: 4px auto;
    font-size: 16px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle.-open {
    padding-right: 32px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .content {
    text-align: center;
    color: var(--mobile-text);
    margin: 0;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body {
    margin-top: 10px;
    padding: 0;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
    margin: 25px auto 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-header {
    text-align: center;
    font-size: 18px;
    display: block;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header h2 {
    text-align: center;
    font-size: 18px;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper {
    margin-bottom: 0;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
    grid-gap: 0;
    gap: 0;
    padding-block: 10px;
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .info {
    width: 90px;
    display: none;
  }

  .single-wallet {
    min-width: 76px;
    max-width: 76px;
    display: block;
  }

  .single-wallet.sidebar .icon {
    width: 56px;
    height: 56px;
    margin: auto;
  }

  .single-wallet.sidebar .content {
    width: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    display: -webkit-box;
    overflow: hidden;
    margin-left: 0 !important;
  }

  .single-wallet.wallet-connect, .single-wallet.my-near-wallet {
    word-break: break-word;
  }

  .single-wallet-get:hover {
    background-color: #4f7cd129;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image, .nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description > p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  .nws-modal-wrapper ::-webkit-scrollbar {
    background: var(--backdrop-bg);
    width: 4px;
    height: 4px;
  }

  .nws-modal-wrapper ::-webkit-scrollbar-thumb:horizontal {
    background: var(--close-button-fill-icon-color);
    border-radius: 10px;
  }
}

.App {
  text-align: center;
  font-family: sans-serif;
}

.hex {
  cursor: pointer;
}

.hex p {
  background: #bcb0ff;
  transition: all .2s;
}

.hex.selected p {
  background: #ffcd4f;
}

.board-gradient {
  position: relative;
  overflow: hidden;
}

.board-gradient:after {
  content: "";
  z-index: 1;
  background: linear-gradient(90deg, #1d115e00 0%, #1d115ecc 60%, #1d115ef2 90%, #1d115e 100%);
  width: 190px;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.board-left-gradient:after {
  background: linear-gradient(-90deg, #1d115e00 0%, #1d115ecc 60%, #1d115ef2 90%, #1d115e 100%);
  left: 0;
  right: auto;
}

.CircularProgressbar {
  vertical-align: middle;
  width: 100%;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset .5s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  dominant-baseline: middle;
  text-anchor: middle;
  font-size: 20px;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: #0000;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #2d3748;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b6cb0;
}

::-webkit-scrollbar-thumb:active {
  background: #000;
}

::-webkit-scrollbar-track {
  background: #1a202c;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666;
}

::-webkit-scrollbar-track:active {
  background: #333;
}

::-webkit-scrollbar-corner {
  background: none;
}

textarea {
  resize: none !important;
}

label {
  height: auto;
}

/*# sourceMappingURL=index.2b8009d0.css.map */
